import React, { useEffect, useState, useMemo, useRef } from "react";
import { useAccount } from "wagmi"; // Hook to get account-related information
import {
  getNonce,
  getProfile,
  logout,
  verifyNonce,
} from "../utils/api/requests"; // API request functions
import Cookies from "js-cookie"; // Utility for managing cookies
import { AuthenticationStatus } from "../utils/constants/enums"; // Enum for authentication statuses
import {
  createAuthenticationAdapter,
  darkTheme,
  lightTheme,
  RainbowKitAuthenticationProvider,
  RainbowKitProvider,
} from "@rainbow-me/rainbowkit"; // RainbowKit for wallet connections and themes
import { AppContext } from "./AppContext"; // Custom context for global state management
import axios from "axios"; // Axios for making HTTP requests
import { toast } from "react-toastify"; // Toast notifications

export function AppContextProvider({ children }) {
  // State variables for managing authentication and user data
  const [authStatus, setAuthStatus] = useState(AuthenticationStatus.LOADING);
  const [user, setUser] = useState(null);
  const [twowayAuth, setTwoAuth] = useState(true);
  const [responseAPI, setResponse] = useState({});
  const [ReCallProfile, setReCallProfile] = useState(false);
  const [messageConnect, setMessageConnect] = useState("");
  const [signatureConnect, setSignatureConnect] = useState("");
  const [addressConnect, setAddressConnect] = useState("");
  const [newUserWalletAddress, setNewUserWalletAddress] = useState("");
  const [error, setError] = useState({});
  const [updateWallet, setUpdateWallet] = useState(false);
  const [tokenUpdateNew, setTokenUpdateNew] = useState(false);
  // Destructuring values from useAccount hook
  const { address, status: walletStatus, chainId } = useAccount();

  // Refs to prevent unnecessary re-renders and handle side effects
  const fetchingStatusRef = useRef(false);
  const verifyingRef = useRef(false);
  const tokenApiProfile = localStorage.getItem("tokenApi"); // Get stored token from localStorage

  useEffect(() => {
    // Function to fetch the user's profile status
    const fetchStatus = async () => {
      // Avoid fetching if already fetching or verifying, or if wallet is reconnecting
      if (
        fetchingStatusRef.current ||
        verifyingRef.current ||
        walletStatus === "reconnecting"
      )
        return;

      // If wallet is disconnected, update auth status and return
      if (walletStatus === "disconnected") {
        setAuthStatus(AuthenticationStatus.UNAUTHENTICATED);
        return;
      }

      fetchingStatusRef.current = true; // Set fetching status to true

      try {
        // API call to get user profile using token from localStorage
        const response = await axios.get(
          "https://promoti-api.qrservicestar.com/User/Profile",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${tokenApiProfile}`,
            },
          }
        );

        // Update user data and auth status based on response
        setUser(response.data);
        setAuthStatus(
          response.status >= 200 &&
            response.status < 300 &&
            walletStatus === "connected"
            ? AuthenticationStatus.AUTHENTICATED
            : AuthenticationStatus.UNAUTHENTICATED
        );
      } catch (error) {
        console.error("Error fetching profile:", error);
        setAuthStatus(AuthenticationStatus.UNAUTHENTICATED);
      } finally {
        fetchingStatusRef.current = false; // Reset fetching status
      }
    };

    fetchStatus(); // Initial fetch when component mounts

    // Fetch profile again when the window gains focus
    const handleFocus = () => {
      fetchStatus();
    };

    window.addEventListener("focus", handleFocus); // Add event listener for window focus
    return () => window.removeEventListener("focus", handleFocus); // Cleanup event listener
  }, [chainId, walletStatus, tokenApiProfile]);

  // Function to update the user's wallet address
  const updateWalletAddress = async (newUserWalletAddress, token) => {
    const message = localStorage.getItem("messageConnect");
    const signature = localStorage.getItem("signatureConnect");
    const address = localStorage.getItem("addressConnect");
    try {
      // API call to update wallet address
      const response = await axios.post(
        "https://promoti-api.qrservicestar.com/User/wallet",
        {
          message: message,
          signature: signature,
          address: address,
          newAddress: newUserWalletAddress,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle response and show notifications
      if (response.status >= 200 && response.status < 300) {
        localStorage.removeItem("tokenApi"); // Remove token after wallet update
        setAuthStatus(AuthenticationStatus.UNAUTHENTICATED);
        toast.success(response.data.message);
      } else {
        toast.error("Failed to update wallet");
      }

      return response;
    } catch (error) {
      console.error("Verification failed", error);
      return null;
    }
  };
  // Memoized authentication adapter for RainbowKit
  const authAdapter = useMemo(() => {
    // If storedUpdateWallet is not found, return false to skip further processing

    return createAuthenticationAdapter({
      getNonce,
      createMessage: ({ nonce, address }) => ({
        message: nonce,
        address,
      }),
      getMessageBody: ({ message }) => message.message,
      verify: async ({ message, signature, address }) => {
        verifyingRef.current = true; // Set verifying status to true

        try {
          const storedUpdateWallet =
            localStorage.getItem("updateWallet") === "true";

          const storedTokenUpdateNew = localStorage.getItem("tokenUpdateNew");

          let response;
          if (storedUpdateWallet) {
            // Run the axios post request to update the wallet address
            response = await axios.post(
              "https://promoti-api.qrservicestar.com/User/wallet",
              {
                message: message.message,
                signature: signature,
                address: message.address,
                newAddress: message.address,
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${storedTokenUpdateNew}`,
                },
              }
            );

            toast.success(response?.data?.message);

            // Set updateWallet to false after updating the wallet
            setUpdateWallet(false);
            localStorage.removeItem("updateWallet");
            localStorage.removeItem("tokenUpdateNew");
            Cookies.remove("secretKey");
            localStorage.removeItem("authStatus");
            localStorage.removeItem("messageConnect");
            localStorage.removeItem("signatureConnect");
            localStorage.removeItem("addressConnect");
            localStorage.removeItem("tokenApi");
            setTimeout(() => {
              window.location.replace("/login");
            }, 500);
          } else {
            // If updateWallet is false, directly verify the nonce
            response = await verifyNonce({
              message: message.message,
              signature,
              address: message.address,
            });

            setResponse(response);

            // Handle two-factor authentication if required
            if (response?.data?.hasOwnProperty("client_secret")) {
              try {
                if (response?.data?.scope === "mfa") {
                  setTwoAuth(false); // Disable two-factor authentication
                } else {
                  setTwoAuth(true); // Enable two-factor authentication
                  Cookies.set("secretKey", response?.data?.client_secret, {
                    path: "/",
                  });
                  localStorage.setItem(
                    "authStatus",
                    AuthenticationStatus.AUTHENTICATED
                  );
                  setReCallProfile(true);
                  setAuthStatus(AuthenticationStatus.AUTHENTICATED);
                  localStorage.setItem("messageConnect", message.message);
                  localStorage.setItem("signatureConnect", signature);
                  localStorage.setItem("addressConnect", message.address);
                  localStorage.setItem("needRefresh", "true");
                  setMessageConnect(message.message);
                  setSignatureConnect(signature);
                  setAddressConnect(message.address);

                  window.location.replace("/onboarding"); // Redirect to onboarding page
                }
              } catch (err) {
                console.log(
                  "Error during two-factor authentication setup:",
                  err
                );
                throw new Error(err);
              }
              setAuthStatus(AuthenticationStatus.AUTHENTICATED);
            }
          }
        } catch (error) {
          toast.error(
            error.response?.data?.message ||
              "An error occurred during verification."
          );
          setAuthStatus(AuthenticationStatus.UNAUTHENTICATED); // Update auth status on failure
          return false;
        } finally {
          verifyingRef.current = false; // Reset verifying status
        }
      },
      signOut: async () => {
        await logout(); // Call logout API
        setAuthStatus(AuthenticationStatus.UNAUTHENTICATED); // Set status to unauthenticated after logout
      },
    });
  }, []);

  if (!authAdapter) {
    console.error("Auth adapter is not properly created.");
    return null; // or return some fallback UI
  }

  // Return the context provider with state values and functions passed down to children
  return (
    <AppContext.Provider
      value={{
        authStatus,
        setAuthStatus,
        user,
        setUser,
        twowayAuth,
        setTwoAuth,
        responseAPI,
        setResponse,
        error,
        setError,
        setUpdateWallet,
        tokenUpdateNew,
        updateWallet,
        ReCallProfile,
        setReCallProfile,
        newUserWalletAddress,
        setNewUserWalletAddress,
        updateWalletAddress,
        messageConnect,
        signatureConnect,
        addressConnect,
        walletStatus,
        setTokenUpdateNew,
      }}
    >
      {/* Provide RainbowKit authentication and theme to children */}
      <RainbowKitAuthenticationProvider
        adapter={authAdapter}
        status={authStatus}
      >
        <RainbowKitProvider
          theme={lightTheme({
            accentColor: "#004499",
            accentColorForeground: "white",
            borderRadius: "small",
            fontStack: "system",
            overlayBlur: "small",
          })}
          coolMode
        >
          {children}
        </RainbowKitProvider>
      </RainbowKitAuthenticationProvider>
    </AppContext.Provider>
  );
}
